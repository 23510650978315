import React from "react";

import IconoTitulo from "./Icono_titulo";
import Paint from "../img/PARA PAGINA/PAINTING.png";
import Remodeling from "../img/PARA PAGINA/REMODELING.png"
import HomeBuilding from "../img/PARA PAGINA/HOME-BUILDING.png"
import Roofing from "../img/PARA PAGINA/ROOFING-2.png"
import "./Work.css";

const ExpertiseSection = () => {
  const services = [
    { icon: Remodeling, title: "Remodeling", description: "Transform your spacewith our remodeling services, including kitchens, bathrooms, and complete home renovations to create the perfect ambiance you desire." },
    { icon: Roofing, title: "Roofing", description: "Roofing services that include installation, repair, and maintenance for residential and commercial buildings, ensuring durability and safety." },
    { icon: Paint, title: "Painting", description: "We offer interior and exterior painting services for all types of structures, using high-quality materials to ensure a lasting and professional finish." },
    { icon: HomeBuilding, title: "Home building", description: "We build your home from the ground up, managing every detail of the construction process with cutting-edge technology and a highly skilled team to ensure your dream home." },
  ];

  return (
    <section className="expertise-section">

      <div className="container-work">
        <h2>We work at everything you need to your house</h2>
        <div className="services">
          {services.map((service, index) => (
            <div key={index} className="service-card">

              <IconoTitulo icon={service.icon} titulo={service.title} ancho={"100%"} />
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExpertiseSection;
